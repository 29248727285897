
// COMPONENTS


// APP
import { onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

export default {
	name: "MyProjectsList",

	props: {
		widgetClasses: String,
	},
	setup() {
		const store = useStore();
		const router = useRouter();

		const colors = ["primary", "secondary", "success", "info", "warning", "danger", "dark"];
		const headers = [
			{ name: "Projects", class: "min-w-150px" },
			{ name: "Access", class: "min-w-110px" },
			{ name: "Actions", class: "min-w-100px text-center" },
		];

		// REACTIVE
		let projectList = ref([]);
		let loading = ref(true);

		// METHODS
		const randomColor = (len: number) => {
			return colors[Math.floor(Math.random() * len)];
		};

		const setActiveState = async ( id, email) => {
			const payload = {
				data: {
					user_id: id,
					email: email	
				}
			};
			ApiService.setHeader();
			const resp = await ApiService.post("admin/users/set-active", payload)
				.then(async ({ data }) => {
					let projects = await loadProjectList();

					if (projects != undefined && Object.prototype.hasOwnProperty.call(projects, 'code') && projects.code == 200 && projects.data.length > 0) {
						projectList.value = projects.data;
					}

					return data.active;
				})
				.catch(({ response }) => {
					console.log(response);
				});
		};

		const loadProjectList = async () => {
			let projects = await store.dispatch(Actions.PROJECTS_LIST);
			return projects;
		};

		const loadUserList = async () => {
			let users = await store.dispatch(Actions.USERS_LIST);
			return users;
		};

		onMounted(async () => {

			setCurrentPageTitle("My Projects");

			let projects = await loadProjectList();

			if (projects != undefined && Object.prototype.hasOwnProperty.call(projects, 'code') && projects.code == 200 && projects.data.length > 0) {
				projectList.value = projects.data;
			}

			loading.value = false;
		});

		return {
			projectList,
			loading,
			randomColor,
			headers,
			setActiveState,
		};
	},

}
